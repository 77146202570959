import './SidebarLayout.css'
import { AiOutlinePlus } from 'react-icons/ai'
import Modal from '@mui/material/Modal';
import { useEffect, useState } from 'react';
import { Close } from '@mui/icons-material';
import { useData } from '../../DataContext';
import { useDispatch } from 'react-redux';
import { addRooms, getRooms } from '../../Store/Slice/user/authSlice';
import { toast } from 'react-toastify';
import { getFromLocalStorage } from '../../utils/localStorage';

const modal = {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'center',
    marginTop: '10px'

}

const paper = {
    width: "500px",
    backgroundColor: " #111111",
    border: "2px solid #000",
    boxShadow: "0 0 10px rgba(0, 0, 0, 0.5)", /* Replace with actual theme shadow value */
    padding: "16px 32px 24px" /* Replace with actual theme spacing values */
}


const NewChat = ({ name }) => {
    // const navigate = useNavigate();
    const [open, setOpen] = useState(false);
    const [roomName, setRoomName] = useState('');
    const { themeChanger } = useData();
    const dispatch = useDispatch();
    const tokens = getFromLocalStorage('authtoken')

    // const loader = useSelector((state) => state && state?.auth && state?.auth?.isLoading);

    useEffect(() => {
        // dispatch(getRooms());
        if (tokens) {
            dispatch(getRooms());
          }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const createChat = async () => {
        // const username = name;
        // eslint-disable-next-line react-hooks/exhaustive-deps
        const data = {
            ROOM_NAME: roomName
        }
        // console.log('create rooms----------------------->')
        if (roomName) {
            dispatch(addRooms(data));
            setOpen(false);
        } else {
            toast.error('room name field is empty')
        }

    }

    return (
        <>
            <div className="create_chat">
                <button className={`create_chat_btn ${themeChanger}`} onClick={() => setOpen(true)}> <AiOutlinePlus /> &nbsp;  Add New Chat</button>
            </div>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                sx={modal}
                open={open}
                onClose={() => setOpen(false)}
            // closeAfterTransition
            // BackdropComponent={Backdrop}
            // BackdropProps={{ timeout: 500 }}
            >
                <div style={paper}>
                    <div className="header-modal">
                        <h4 className="modal-title" style={{ color: 'white' }}>Add New Chat</h4>
                        <Close onClick={() => setOpen(false)} className='new-chat-close-btn' style={{ color: 'white' }} />
                    </div>
                    <div className="modal-body">
                        <div className="row">
                            <div className="col-md-12">
                                <input className="form-control input-new-chat" style={{ border: 'none' }} placeholder="Enter Chat title" type="text" name="category-name" value={roomName} onChange={e => setRoomName(e.target.value)} />
                            </div>
                        </div>
                    </div>
                    <div className="footer-modal">
                        <button type="button" className="btn btn-primary waves-effect waves-light save-category btn-sm" onClick={createChat}>Save</button>
                        <button type="button" className="btn btn-white closebtn-new-chat btn-sm" onClick={() => setOpen(false)}>Close</button>
                    </div>
                </div>
            </Modal>
        </>
    )
}

export default NewChat