import './Login.css'
import botImg from '../../../images/bot.png'
import { useMsal } from '@azure/msal-react';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { userLogin } from '../../../Store/Slice/user/authSlice';
import Loader from '../../../components/Loader/Loader';
import axiosInstance from '../../../utils/axiosInstance';

const Login = () => {
    const { instance } = useMsal();
    const dispatch = useDispatch();
    const [close, setClose] = useState(false);
    const loader = useSelector((state) => state && state?.auth && state?.auth?.isLoading)
    const initialState = {
        'username': '',
        'password': '',
    }
    const [user, setUser] = useState(initialState);
    const [submissionfailed, setSubmissionFailed] = useState(false)

    const inputChangeHandle = e => {
        const { name, value } = e.target
        setUser({ ...user, [name]: value })
    }

    // const handleSubmit = (e) => {
    //     e.preventDefault();
    //     instance.loginRedirect({

    //     });
    // };

    const handleSubmit = (e) => {
        e.preventDefault();
        instance.loginPopup({
            redirectUri:'/'
        }).then(() => {
            console.log('Login successful'); // Log successful login
            window.location.reload(); // Refresh the page
        }).catch(error => {
            console.error('Login error:', error); // Log any login errors
            // Handle login error if needed
        });
    };
    
    

    const handleCloseSubmit = () => {
        setClose(true)
    }
    const xapiextractor = async (user) => {
        try {
            const response = await axiosInstance.get('/user/x-api-finder', {
                headers: {
                    username: user.username,
                    password: user.password,
                },
            });

            // console.log('response x-pai-key',response?.data)
            if(response?.data?.data[0]?.API_USERNAME){
                return {
                    username: response?.data?.data[0]?.API_USERNAME,
                    password: response?.data?.data[0]?.PASSWORD,
                    x_api_key: response?.data?.data[0]?.X_API_KEY,
                };
            }else if (response?.data?.data[0]?.USER_NAME) {
                return {
                    username: response?.data?.data[0]?.USER_NAME,
                    password: response?.data?.data[0]?.PASSWORD,
                    x_api_key: response?.data?.data[0]?.X_API_KEY,
                };
                
            }

            
        } catch (error) {
            // Handle errors, e.g., toast.error(error.message);
            console.error('Error:', error);
            return null;
        }
    };

    const loginUser = async () => {
        

        if (user.username === '' || user.password === '') {
            setSubmissionFailed(true);
            return;
        }

        const extractedDetails = await xapiextractor(user);
        // console.log('extractor', extractedDetails)
        if (extractedDetails && extractedDetails.username !== '' && extractedDetails.password !== '' && extractedDetails.XApiKey !== '') {
            // console.log('extraction details',extractedDetails)
            
            dispatch(userLogin(extractedDetails));
        } else {
            // Handle invalid response data
            console.error('Invalid response data');
        }



    };


    //console.log('user', user)

    const backForm = () => {
        setUser(initialState);
        setClose(false);
    }

    return (
        <>
            {
                loader && <Loader />
            }
            <div className="top">
                <div className="bot">
                    <img src={botImg} alt="Bot" className="img-bot" />
                </div>
                <h2 className="text-white mt-3">Welcome to</h2>
                <h6 className="text-white">InfusAI</h6>


                {
                    close &&
                    <div className="client_login_form">
                        <div className="mb-1" style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                            <label for="exampleFormControlInput1" className="form-label">Username</label>
                            <input type="email"
                                className="form-control"
                                id="exampleFormControlInput1"
                                value={user.username}
                                onChange={inputChangeHandle}
                                name='username'
                                placeholder="" />
                            {user.username === '' && submissionfailed && (
                                <small className="text-danger">username is required</small>
                            )}
                        </div>
                        <div className="mb-1" style={{ display: 'flex', alignItems: 'flex-start', flexDirection: 'column' }}>
                            <label for="exampleFormControlInput2" className="form-label">Password</label>
                            <input type="password"
                                className="form-control"
                                id="exampleFormControlInput2"
                                onChange={inputChangeHandle}
                                value={user.password}
                                name='password'
                                placeholder="" />
                            {user.password === '' && submissionfailed && (
                                <small className="text-danger">username is required</small>
                            )}
                        </div>
                        <div className="mb-1" style={{ display: 'flex', gap: '4rem', justifyContent: 'center' }}>
                            <button type="submit" onClick={backForm} className="start-btn mt-3">Back</button>
                            <button type="submit" onClick={loginUser} className="start-btn mt-3">Submit</button>
                        </div>
                    </div>
                }
                {
                    !close &&
                    <div className="log_in_btn">
                        <form
                            className="col-md-3 custom-form">
                            <button onClick={handleSubmit} className="start-btn mt-3">Microsoft Login</button>
                        </form>
                        <form
                            className="col-md-3 custom-form">
                            <button onClick={handleCloseSubmit} className="start-btn mt-3">Client Login</button>
                        </form>
                    </div>
                }

            </div>
        </>
    )
}

export default Login