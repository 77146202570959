import * as React from 'react';
import { Canvas } from '@react-three/fiber';
import { Experience } from './Experience';
import ReactDOM from 'react-dom/client';
import { useEffect } from 'react';

function CanvasWrapper() {
    useEffect(() => {
        ReactDOM.createRoot(document.getElementById('avatar-root')).render(
            <Canvas shadows camera={{ position: [0, 0, 9], fov: 42 }}>
                {/* <color attach="background" args={['#ececec']} /> */}
                <Experience />
            </Canvas>

        )
    }, [])

    return (
        <></>
    );
}

export default CanvasWrapper