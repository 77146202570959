import { Environment, OrbitControls, useTexture } from "@react-three/drei";
import { useThree } from "@react-three/fiber";
import { AshishAvatar } from "./AshishAvatar";
import { useState } from "react";

export const Experience = () => {
  // const texture = useTexture("textures/youtubeBackground.png");
  // const viewport = useThree((state) => state.viewport);
  const [isSecondAvatar, setIsSecondAvatar] = useState(false);

    const handleDoubleClick = () => {
        setIsSecondAvatar(prevState => !prevState);
    };

 

  return (
    <>
      <OrbitControls enablePan={false} enableRotate={false} enableZoom={false} />
      {/* <Avatar position={[-1, -3, 3.5]} scale={2} rotation={[0.04, 0.75, 0]} /> */}
      {/* <AshishAvatar position={[-2, -1.8, 4]} scale={2} rotation={[0, 0.20, 0]} /> */}

      {/* <AshishAvatar position={[-2, -6.2, 4]} scale={3.5} rotation={[0, 0.2, 0]} /> */}

      <group onDoubleClick={handleDoubleClick}>
            {isSecondAvatar ? (
               <AshishAvatar key="e1" position={[-2, -1.8, 4]} scale={2} rotation={[0, 0.20, 0]} />
            ) : (
                <AshishAvatar key="e2" position={[-2, -6.2, 4]} scale={3.3} rotation={[0, 0.2, 0]} />
            )}
            
            
           
        </group>

        

      {/* <Environment preset="sunset" /> */}
      <Environment files="/images/studio_small_09_4k.hdr"/>
      {/* <mesh> */}
        {/* <planeGeometry args={[viewport.width, viewport.height]} /> */}
        {/* <meshBasicMaterial map={texture} /> */}
      {/* </mesh> */}
    </>
  );
};
