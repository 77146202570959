import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { addRoomThunk, countDocsThunk, createFolderThunk, createTokenFOrSocialThunk, deleteDocsThunk, deleteRoomThunk, getChatThunk, getRoomThunk, getUserThunk, tokenVlaidationThunk, updateRoomThunk, uploadFilesThunk, userChatThunk, userLoginThunk } from "./authThunk";
import { addToLocalStorage, removeFromLocalStorage } from "../../../utils/localStorage";
// import Loader from "../../../components/Loader/Loader";
 
const initialState = {
    isLoading: false,
    user: [],
    docs: [],
    rooms: [],
    chat: [],
    token: {},
    username:{},
    folderStatus: {},
    statusChanger: {
        addNewChat: false,
        addNewRoom: false,
        addNewDocuments: false,
    },
    authToken: null,
    isLoggedIn: false,
}
 
export const userLogin = createAsyncThunk(
    'user/create-auth-token',
    async (data, thunkApi) => {
        return userLoginThunk('/user/create-social-token', data, thunkApi);
    }
)
 
export const createFolder = createAsyncThunk(
    'user/create-folder',
    async (data, thunkApi) => {
        return createFolderThunk('/user/create-folder', data, thunkApi);
    }
)
 
export const createTokenForSocial = createAsyncThunk(
    'user/create-social-token',
    async (data, thunkApi) => {
        return createTokenFOrSocialThunk('/user/create-social-token', data, thunkApi);
    }
)
 
export const uploadFiles = createAsyncThunk(
    'user/upload-files',
    async (data, thunkApi) => {
        return uploadFilesThunk('/user/upload-files', data, thunkApi);
    }
)
 
export const userChat = createAsyncThunk(
    'user/post-chat',
    async (data, thunkApi) => {
        return userChatThunk('/user/user-chat', data, thunkApi);
    }
)
 
export const getChat = createAsyncThunk(
    'user/get-chat',
    async (data, thunkApi) => {
        return getChatThunk(`/user/user-chat?ROOM_ID=${data}`, thunkApi);
    }
)
 
 
 
export const deleteDocs = createAsyncThunk(
    'user/delete-documents',
    async (data, thunkApi) => {
        return deleteDocsThunk(`/user/delete-documents?DOCUMENTS_ID=${data}`, thunkApi);
    }
)
 
export const countDocs = createAsyncThunk(
    'user/count-docs',
    async (data, thunkApi) => {
        return countDocsThunk('/user/count-docs', data, thunkApi);
    }
)
export const getRooms = createAsyncThunk(
    'user/get-room',
    async (data, thunkApi) => {
        return getRoomThunk('/user/chat-rooms', data, thunkApi);
    }
)
export const addRooms = createAsyncThunk(
    'user/add-rooms',
    async (data, thunkApi) => {
        return addRoomThunk('/user/chat-rooms', data, thunkApi);
    }
)
export const updateRooms = createAsyncThunk(
    'user/update-rooms',
    async (data, thunkApi) => {
        return updateRoomThunk('/user/chat-rooms', data, thunkApi);
    }
)
 
export const deleteRooms = createAsyncThunk(
    'user/delete-rooms',
    async (data, thunkApi) => {
        return deleteRoomThunk(`/user/chat-rooms?ROOM_ID=${data}`, thunkApi);
    }
)
 
export const tokenValidation = createAsyncThunk(
    'user/token-validation',
    async (data, thunkApi) => {
        return tokenVlaidationThunk(`/user/token-validation?token=${data}`, thunkApi);
    }
)
 
export const getUser = createAsyncThunk(
    'user/get-user-validation',
    async (data, thunkApi) => {
        return getUserThunk(`/user/token-validation?token=${data}`, thunkApi);
    }
)
 
const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        loginSomething: (state) => {
            state.isLoading = true;
        },
 
    },
 
    extraReducers: (builder) => {
        builder.addCase(userLogin.pending, (state) => {
            state.isLoading = true;
 
        })
            .addCase(userLogin.fulfilled, (state, { payload }) => {
                state.user = payload;
                console.log('user login payload', payload);
                addToLocalStorage(payload?.data?.token?.access?.token, 'authtoken');
                state.isLoading = false;
                window.location.reload()
            })
            .addCase(userLogin.rejected, (state, { payload }) => {
                state.isLoading = true;
                toast.error('login failed');
            });
        builder.addCase(createTokenForSocial.pending, (state) => {
            // state.isLoading = false;
 
        })
            .addCase(createTokenForSocial.fulfilled, (state, { payload }) => {
                state.user = payload;
                console.log('tokencreation', payload);
                addToLocalStorage(payload?.data?.token?.access?.token, 'authtoken');
                // state.isLoading = false;
                window.location.reload()
            })
            .addCase(createTokenForSocial.rejected, (state, { payload }) => {
                state.isLoading = false;
                toast.error('login failed');
            });
        builder.addCase(createFolder.pending, (state) => {
        })
            .addCase(createFolder.fulfilled, (state, { payload }) => {
                console.log('create folder payload', payload)
                // if (payload?.data?.status === 401) {
                //     toast.error('Session Expaired');
                //     removeFromLocalStorage('authtoken');
                //     //window.location.reload()
                // }
            })
            .addCase(createFolder.rejected, (state, { payload }) => {
                //console.log'createerror', payload)
            });
        builder.addCase(uploadFiles.pending, (state) => {
            state.isLoading = true;
        })
            .addCase(uploadFiles.fulfilled, (state, { payload }) => {
                state.isLoading = false;
            })
            .addCase(uploadFiles.rejected, (state, { payload }) => {
                state.isLoading = false;
                toast.error('fileupload failed')
            });
        builder.addCase(userChat.pending, (state) => {
            state.isLoading = true;
            state.statusChanger.addNewChat = false;
        })
            .addCase(userChat.fulfilled, (state, { payload }) => {
                // state.chat = payload;
               if(window.avatarSpeech) {window.avatarSpeech(payload?.data?.data?.response);}
                console.warn(' user payload data', payload);
                state.isLoading = false;
                state.statusChanger.addNewChat = true;
            })
            .addCase(userChat.rejected, (state, { payload }) => {
                state.isLoading = false;
                state.statusChanger.addNewChat = false;
                toast.error('chat failed');
            });
        builder.addCase(deleteDocs.pending, (state) => {
            state.isLoading = true;
        })
            .addCase(deleteDocs.fulfilled, (state, { payload }) => {
                if (payload?.data?.status === 1) {
                    toast.success('documents deleted successfully')
                }
                state.isLoading = false;
            })
            .addCase(deleteDocs.rejected, (state, { payload }) => {
                state.isLoading = false;
                toast.error('login failed');
            });
        builder.addCase(countDocs.pending, (state) => {
        })
            .addCase(countDocs.fulfilled, (state, { payload }) => {
                state.docs = payload;
            })
            .addCase(countDocs.rejected, (state, { payload }) => {
                toast.error('docs fetch failed');
            });
        builder.addCase(getRooms.pending, (state) => {
            state.isLoading = true;
        })
            .addCase(getRooms.fulfilled, (state, { payload }) => {
                state.isLoading = false;
                state.rooms = payload?.data;
                console.log('rooms payload', payload);
                if (payload?.status === 401) {
                    toast.error('Session Expaired');
                    removeFromLocalStorage('authtoken');

                    localStorage.clear();
                    window.location.reload()
                }
            })
            .addCase(getRooms.rejected, (state, { payload }) => {
                state.isLoading = false;
                toast.error('rooms fetch failed');
            });
        builder.addCase(addRooms.pending, (state) => {
            state.isLoading = true;
            state.statusChanger.addNewRoom = false;
        })
            .addCase(addRooms.fulfilled, (state, { payload }) => {
                state.isLoading = false;
                console.log('create room', payload)
                if (payload?.status === 1) {
                    state.statusChanger.addNewRoom = true;
                    toast.success('room add successfully');
                }
            })
            .addCase(addRooms.rejected, (state, { payload }) => {
                state.isLoading = false;
                state.statusChanger.addNewChat = false;
                toast.error('rooms fetch failed');
            });
        builder.addCase(updateRooms.pending, (state) => {
        })
            .addCase(updateRooms.fulfilled, (state, { payload }) => {
                // if (payload?.status === 1) {
                //     toast.success('room update successfully');
                // }
            })
            .addCase(updateRooms.rejected, (state, { payload }) => {
                // toast.error('rooms update failed');
            });
        builder.addCase(deleteRooms.pending, (state) => {
        })
            .addCase(deleteRooms.fulfilled, (state, { payload }) => {
                if (payload?.status === 1) {
                    toast.success('room deleted successfully');
                }
            })
            .addCase(deleteRooms.rejected, (state, { payload }) => {
                toast.error('rooms deleted failed');
            });
        builder.addCase(getChat.pending, (state) => {
            state.isLoading = true;
        })
            .addCase(getChat.fulfilled, (state, { payload }) => {
                console.warn({payload})
                state.chat = payload.data;
                state.isLoading = false;
                state.statusChanger.addNewChat = false;
                //console.log'get chat',payload?.data)
                // if (payload?.status === 1) {
                //     toast.success('room update successfully');
                // }
                if (payload?.status === 401) {
                    toast.error('Session Expaired');
                    removeFromLocalStorage('authtoken');
                    localStorage.clear();
                    //window.location.reload()
                }
            })
            .addCase(getChat.rejected, (state, { payload }) => {
                state.isLoading = false;
                state.statusChanger.addNewChat = false;
                toast.error('rooms get failed');
            });
        builder.addCase(tokenValidation.pending, (state) => {
            state.isLoading = true;
        })
            .addCase(tokenValidation.fulfilled, (state, { payload }) => {
                // console.log('tokenvalidationpayload',payload)
                if (payload?.status === 401) {
                    toast.error('Session Expaired');
                    console.log('authtoken payload', payload);
                    state.isLoading = false;
                    state.token = payload;
                    removeFromLocalStorage('authtoken');
                    localStorage.clear();
                    window.location.reload()
                }
                else {
                    state.token = payload.data?.decodedToken;
                    state.isLoading = false;
                    console.log('authtoken payload ', payload);
                    const authToken = new URLSearchParams(window.location.search).get('authtoken');
                    addToLocalStorage(authToken, 'authtoken');
                    // addToLocalStorage(payload?.data?.decodedToken,'userdetails')
                    window.location.reload()
                }
                // state.statusChanger.addNewChat = false;
                //console.log'get chat',payload?.data)
                // if (payload?.status === 1) {
                //     toast.success('room update successfully');
                // }
            })
            .addCase(tokenValidation.rejected, (state, { payload }) => {
                state.isLoading = false;
                // state.statusChanger.addNewChat = false;
                console.log('authtoken error', payload);
                toast.error('token validation failed');
            });
 
    
            builder.addCase(getUser.pending, (state) => {
                state.isLoading = true;
            })
                .addCase(getUser.fulfilled, (state, { payload }) => {
                    // console.log('tokenvalidationpayload',payload)
                    if (payload?.status === 401) {
                        toast.error('Session Expaired');
                        console.log('authtoken payload', payload);
                        state.isLoading = false;
                        state.token = payload;
                        removeFromLocalStorage('authtoken');
                        localStorage.clear();
                        window.location.reload()
                    }
                    else {
                        // state.token = payload.data?.decodedToken;
                        state.isLoading = false;
                        console.log('authtoken payload', payload?.data?.decodedToken?.sub);
                        // const authToken = new URLSearchParams(window.location.search).get('authtoken');
                        // addToLocalStorage(authToken, 'authtoken');
                        state.username = payload?.data?.decodedToken;
                        addToLocalStorage(payload?.data?.decodedToken?.sub,'userdetails')
                        // window.location.reload()
                    }
                    // state.statusChanger.addNewChat = false;
                    //console.log'get chat',payload?.data)
                    // if (payload?.status === 1) {
                    //     toast.success('room update successfully');
                    // }
                })
                .addCase(getUser.rejected, (state, { payload }) => {
                    state.isLoading = false;
                    // state.statusChanger.addNewChat = false;
                    console.log('authtoken error', payload);
                    toast.error('token validation failed');
                });
    }
})
 
export const { loginSomething } = authSlice.actions;
export default authSlice.reducer;
 
 
 