import * as React from "react";
import "./App.css";
import {
  MsalProvider,
  // , useIsAuthenticated, useMsal
} from "@azure/msal-react";
import { InteractionRequiredAuthError } from "@azure/msal-browser";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
} from "@azure/msal-react";
import Login from "./Pages/Authentication/Login/Login";
import Chat from "./Pages/Chat/Chat/Chat";
import ResponsiveDrawer from "./components/SideNavlayout/LeftsideBarLayout";
import { useData } from "./DataContext";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import useMediaQuery from "@mui/material/useMediaQuery";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { getFromLocalStorage } from "./utils/localStorage";
import { useDispatch } from "react-redux";
import {
  createFolder,
  createTokenForSocial,
  getUser,
} from "./Store/Slice/user/authSlice";
import OuterLink from "./Pages/OuterLink/OuterLink";
import axiosInstance from "./utils/axiosInstance";

function App({ msalInstance }) {
  const { boders, themeChanger, login } = useData();
  const dispatch = useDispatch();
  const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");
  const token = getFromLocalStorage('authtoken');
  
  const theme = React.useMemo(
    () =>
      createTheme({
        palette: {
          mode: prefersDarkMode ? "dark" : "light",
        },
      }),
    [prefersDarkMode]
  );

  var request = {
    scopes: ["User.Read"],
  };

  msalInstance
    .acquireTokenSilent(request)
    .then(async (tokenResponse) => {
      // Do something with the tokenResponse
      // console.log('tokenresponse', tokenResponse?.account)
      const data = {
        username: tokenResponse?.account?.username,
      };
      const xapiextractor = async (datas) => {
        try {
          const response = await axiosInstance.get("/user/x-api-finder", {
            headers: {
              username: datas?.username,
              // password: user.password,
            },
          });

          // console.log('response x-pai-key',response?.data)
          if (response?.data?.data[0]?.API_USERNAME) {
            return {
              username: response?.data?.data[0]?.API_USERNAME,
              password: response?.data?.data[0]?.PASSWORD,
              x_api_key: response?.data?.data[0]?.X_API_KEY,
            };
          } else if (response?.data?.data[0]?.USER_NAME) {
            return {
              username: response?.data?.data[0]?.USER_NAME,
              password: response?.data?.data[0]?.PASSWORD,
              x_api_key: response?.data?.data[0]?.X_API_KEY,
            };
          }
        } catch (error) {
          // Handle errors, e.g., toast.error(error.message);
          console.error("Error:", error);
          return null;
        }
      };


      const extractedDetails = await xapiextractor(data);


      console.log('extraction details',extractedDetails);
      if(!token){
        console.log('token is empty',token)
        dispatch(createTokenForSocial(extractedDetails));
        // window.location.reload();
      }


    })
    .catch(async (error) => {
      if (error instanceof InteractionRequiredAuthError) {
        // fallback to interaction when silent call fails
        return msalInstance.acquireTokenPopup(request);
      }
      // handle other errors
    });

  return (
    <>
      <MsalProvider instance={msalInstance}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <div className="containerfluids">
            <div className={`main_parent_div ${themeChanger}`}>
              <div className={`parent-top ${themeChanger}`}>
                <Pages />
              </div>
              <div
                className={`copyrights ${themeChanger}`}
                style={{
                  borderWidth:
                    boders || login ? "0px 0px 0px 0px" : "0px 0px 0px 80px",
                }}
              >
                <p className="capyrights_para">
                  InfusAi depends on third-party models and the information
                  generated may not be accurate or offensive information that
                  doesn't represent InfusAi's views. &nbsp;
                </p>
              </div>
            </div>
          </div>
        </ThemeProvider>
      </MsalProvider>
      <ToastContainer />
    </>
  );
}

const Pages = ({ msls }) => {
  const tokens = getFromLocalStorage("authtoken");
  // const loader = useSelector((state) => state && state?.auth && state?.auth?.isLoading)
  // console.log('loader', loader)
  const dispatch = useDispatch();
  // const navigate  = useNavigate();
  // const authToken = new URLSearchParams(window.location.search).get('authtoken');

  // const params = useParams();
  React.useEffect(() => {
    if (tokens) {

      console.log('have token')
      dispatch(createFolder());
      dispatch(getUser(tokens));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tokens]);

  React.useEffect(() => {
    console.log('i didn-t have token')
    if (tokens) {

      dispatch(createFolder());
      dispatch(getUser(tokens));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Router>
      {tokens ? (
        // { tokens &&
        <ResponsiveDrawer>
          <Routes>
            <Route path="/rooms/:roomId" element={<Chat />} />
            <Route path="/" element={<Chat />} />
          </Routes>
        </ResponsiveDrawer>
      ) : (
        // }
        <>
          <AuthenticatedTemplate>
            {tokens && (
              <ResponsiveDrawer>
                <Routes>
                  <Route path="/rooms/:roomId" element={<Chat />} />
                  <Route path="/" element={<Chat />} />
                </Routes>
              </ResponsiveDrawer>
            )}
          </AuthenticatedTemplate>
          <UnauthenticatedTemplate>
            <Routes>
              <Route path="/userlogin" element={<OuterLink />} />
            </Routes>
            <Login />
          </UnauthenticatedTemplate>
        </>
      )}
    </Router>
  );
};

export default App;
